<template>
  <div>
    <div class="ml-6">
      <h1 class="title m-6">
        Semantik SEO keywords finder
      </h1>
    </div>

    <div class="loader-semrush">
      <vs-dialog prevent-close not-close width="500px" class="loader-semrush" v-model="activePopup">
        <template #header>
          <h4 class="not-margin">Welcome to <b>Semantik</b></h4>
        </template>
        <div class="con-content">
          <div ref="content"></div>
        </div>
        <template #footer>
          <div class="footer-dialog">
            <p>When logged in, click <a href="https://app.semantik-seo.com/">here</a></p>
            <br>
            <vs-button
                    href="https://www.semrush.com/login"
                    size="l"
                    blank
            >
              Log in to Semrush
            </vs-button>
            <br/>
            <a @click="()=>{active=!active; activePopup=!activePopup}">I don't have SemRush account</a>
          </div>
        </template>
      </vs-dialog>
    </div>


    <div class="center">
      <vs-dialog blur v-model="active">
        <template #header>
          <h4 class="not-margin">Give us <b>Information</b></h4>
        </template>
        <br/>
        <p>Can we contact you when we have a beta for your SEO tool?</p>
        <br/>
        <div class="con-form">
          <vs-input v-model="email" placeholder="Your email">
            <template #icon>
              <i class='bx bx-mail-send'/>
            </template>
          </vs-input>
          <br/>
          <b-field>
            <b-taginput
                v-model="tools"
                ellipsis
                maxtags="5"
                icon-pack="fas"
                icon="tag"
                type="is-primary"
                close-type="is-danger"
                placeholder="Enter the SEO tool(s) you use"
                aria-close-label="Delete this tag">
            </b-taginput>
          </b-field>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block @click="sendLead">
              Validate
            </vs-button>

          </div>
        </template>
      </vs-dialog>
    </div>

  </div>
</template>

<script>
import {addLeadInformation} from "@/utils/lead/addLead";

export default {
  name: "ConnectedToSemRush",
  data() {
    return {
      tools: [],
      active: false,
      activePopup: true,
      email: '',
    }
  },

  mounted() {
    this.listenCookieToken()
  },

  updated(){
    console.log(this.activePopup);
    if(!this.activePopup && !this.active){
      this.activePopup = true
    }
  },


  methods:{
    sendLead(){
      addLeadInformation(this.email, this.tools).then(res=>{
        console.log(res);
        this.active = false;
        this.activePopup = true;
      });
    },


    listenCookieToken() {

      this.$vs.loading({
        target: this.$refs.content,
        type: 'waves',
        color: 'primary',
        text: "You're not connected to Semrush. Please Log in to Semrush to access Semantik"
      })

    }

  }

}
</script>
<style lang="scss">
  .loader-semrush {
    .vs-loading__load__text {
      color: $dark;
      margin-top: 25px;
      font-weight: normal;
      font-size: 0.9em;
    }

    .vs-loading__load__animation{
      height: 50px;
      width: 50px;
    }

    .con-content{
      height: 150px;
    }

    .footer-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      font-size: 0.9em;
    }
  }

</style>
